import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Pricing from "../components/pricing"
import Clients from "../components/clients"
import Service from "../components/services"

import AboutImg from "../images/startup.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`Custom Software`, `Software Development`, `Wordpress`, `gatsby`, `python`, `react`, `Small Business`, `App Development`, `Marketing`, `SEO`]} />
    <Banner />
    <section className="section bg-light feather-bg-img" id="features">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="mb-4 mb-lg-0">
                        <img src={AboutImg} alt="about" className="img-fluid d-block mx-auto" />
                    </div>
                </div>

                <div className="col-lg-5 offset-lg-1">
                    <h3 className="font-weight-semibold line-height-1_4 mb-4">About Us</h3>
                    <p className="text-muted mb-4">
                      Chom Technologies is a custom software development company that offers custom-tailored solutions for businesses and individuals looking to take on the world through software. We promise quality software that aligns with industry standards, <b>Always</b>.
                    </p>

                    <p className="text-muted mb-4">
                      We offer results driven, custom software solutions for businesses ready to grow and offer their customers with better products.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <Service />
    <Pricing />
    <Clients />
  </Layout>
)

export default IndexPage