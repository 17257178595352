import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

const Pricing = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfPricing {
            edges {
              node {
                acf {
                  title
                  payment_type
                  price
                  pricing_options
                }
              }
            }
        }
      }
    `}
    render={data => (
        <section class="section bg-light" id="pricing">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="text-center mb-5">
                            <h3 class="title mb-3">Choose Your Plan</h3>
                        </div>
                    </div>
                </div>

                <div class="row">
                    {data.allWordpressAcfPricing.edges.map(pricing => (
                        <div class="col-lg-4" key={pricing.node.id}>
                            <div class="pricing-box rounded text-center p-4">
                                <h4 class="title mb-3">{pricing.node.acf.title}</h4>
                                <h1 class="font-weight-bold mb-0">
                                    <b>
                                        <sup class="h4 mr-2 font-weight-bold">R</sup>
                                        {pricing.node.acf.price}
                                    </b>
                                </h1>
                                <p class="text-muted font-weight-semibold">
                                    {pricing.node.acf.payment_type}
                                </p>
                                <p>{pricing.node.acf.pricing_options}</p>
                                <Link 
                                    to="/contact"
                                    className="btn btn-outline-primary pr-btn"
                                >
                                    Contact Us
                                </Link>
                                <div class="mt-4">
                                    <div class="hero-bottom-img">
                                        <img src="images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )}
  />
)

export default Pricing