import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(slug: {eq: "home"}){
            acf{
                banner_section {
                    banner_title
                    banner_text
                    banner{
                        localFile {
                            childImageSharp {
                                sizes(maxWidth: 1200) {
                                    ...GatsbyImageSharpSizes
                                }
                            }
                        }
                    }
                }
            }
        }
      }
    `}
    render={data => (
        <section className="hero-1-bg" id="home">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">
                        <h1 className="hero-1-title text-dark font-weight-bold text-shadow mb-5">
                            {data.wordpressPage.acf.banner_section.banner_title}
                        </h1>
                        <div className="w-75 mb-lg-0">
                            <p className="text-muted mb-3 pb-4 font-size-17">
                                {data.wordpressPage.acf.banner_section.banner_text}
                            </p>
                        </div>
                        <div class="mt-2 mb-5 pb-5">
                            <Link 
                                to="/contact"
                                className="btn btn-primary text-uppercase"
                            >
                               Contact Us 
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className=" mt-5 mt-lg-0">
                            <Img
                                sizes={data.wordpressPage.acf.banner_section.banner.localFile.childImageSharp.sizes}
                                alt={data.wordpressPage.acf.banner_section.banner_title}
                                className="img-fluid d-block mx-auto" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )}
  />
)

export default Banner